import DownloadFile from '../download-ifc';
export let download : DownloadFile = {
    title: "Server ZIP",
    description: "Can be started as Server and accessed via Browser, locally also. Java JDK 21 is required.",
    href: "https://kt2l-downloads.s3.eu-central-1.amazonaws.com/snapshots/kt2l-server-2024-08-02.zip",
    href_help: "/docs/installation/server",
    size: "301MB",
    enabled: true,
    created: "2024-08-02"
}
